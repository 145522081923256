import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
  OPERATOR_TYPE,
  OPERATOR_SORT,
  type ISearchParams,
} from '@features/filters/types';

interface InitialState {
  searchParams: ISearchParams;
}

export const initialCodeCampaignSearchParams = {
  page: 1,
  size: 10,
  filters: [{ value: true, field: 'enabled', type: OPERATOR_TYPE.EQUAL }],
  sorts: [
    {
      field: 'modified_at',
      order: OPERATOR_SORT.DESC,
    },
  ],
};

const initialState: InitialState = {
  searchParams: initialCodeCampaignSearchParams,
};

const codeCampaignsSlice = createSlice({
  initialState,
  name: 'codeCampaignsSlice',
  reducers: {
    setSearchParams(state: InitialState, action: PayloadAction<ISearchParams>) {
      state.searchParams = action.payload;
    },
  },
});

export default codeCampaignsSlice.reducer;

export const { setSearchParams } = codeCampaignsSlice.actions;

import { lazy } from 'react';
import { Suspendable } from '@components';
import { type RouteObject } from 'react-router-dom';

import { ROUTES } from '@constants';

const CodeCampaigns = Suspendable(
  lazy(() =>
    import('@modules/codeCampaigns/controller/index').then((module) => ({
      default: module.default,
    }))
  )
);

export const CodeCampaignRoutes: RouteObject[] = [
  {
    element: <CodeCampaigns />,
    path: ROUTES.CODE_CAMPAIGNS.ROOT,
  },
];
